import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ChatBot from './components/ChatBot'; // Import the ChatBot component

// Regular rendering of the main App
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// Expose the ChatBot component globally to be used as a widget
window.renderChatBot = (elementId) => {
  const chatbotRoot = ReactDOM.createRoot(document.getElementById(elementId));
  chatbotRoot.render(<ChatBot />);
};
