/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Send, Car, Search, Cross, X } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import { SendHorizontal, Banknote, SlidersHorizontal } from "lucide-react";
import useMediaQuery from "../hooks/useMediaQuery.tsx";
import SendButton from "../assets/images/Button.svg";
import ChatIcon from "../assets/images/chat.svg";
import { renderToStaticMarkup } from "react-dom/server";
const quickOptions = [
  { id: "running_cost", text: "What are the best features of this car?" },
  { id: "specs", text: "How does it compare to a similar model?" },
  // { id: "customer-review", text: "Customer Reviews" },
];
const quickOptionsWithIcon = [
  {
    id: "running_cost",
    text: "Running Costs",
    icon: <Banknote className="h-6 w-6 me-3" />,
  },
  {
    id: "specs",
    text: "Specifications",
    icon: <SlidersHorizontal className="h-5 w-5 me-3" />,
  },
];
export default function ChatBot() {
  const [messages, setMessages] = useState([]);
  const [messagesBot, setMessagesBot] = useState([]);
  const [chunkMessages, setChunkMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSocket, setIsLoadingSocket] = useState(false);
  const [showWelcome, setShowWelcome] = useState(true);
  const [socket, setSocket] = useState(null);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [featuresArray, setFeaturesArray] = useState([]);
  const [vrm, setVrm] = useState("");
  const params = new URLSearchParams(window.location.search);
  const mode = params.get("mode");
  const [hex, setHex] = useState("#2563eb");
  const screen = useMediaQuery("(max-width: 490px)");
  const responseRef = useRef(""); // For streaming response build
  

  // console.log(screen);

  // const scrollToBottom = () => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  useEffect(() => {
    let accumulatedChunks = "";

    const newSocket = new WebSocket(
      mode === "embed"
        ? "wss://car-iq-backend.staging.autofintech.link/ws/chat"
        : "wss://car-iq-backend.staging.autofintech.link/ws/chat-stream"
    );
    newSocket.onopen = () => {
      setIsLoadingSocket(true);
      console.log("WebSocket connected");
    };

    newSocket.onmessage = (event) => {
      accumulatedChunks += event.data;

      const messages = accumulatedChunks.split("\n");

      for (let i = 0; i < messages.length - 1; i++) {
        const message = messages[i].trim();
        if (message) {
          let cleanedMessage = message
            .replace(/Streaming.*?ChatOutput-[A-Za-z0-9]+/, "") // Remove unwanted metadata
            .trim();
          const formattedText = formatMessage(cleanedMessage); // Call your format function here
          // const formattedText = cleanedMessage; // Call your format function here

          setIsLoading(false);
          setMessagesBot((prevMessagesBot) => [
            ...prevMessagesBot,
            { id: uuidv4(), text: formattedText, sender: "bot" },
          ]);
        }
      }

      accumulatedChunks = messages[messages.length - 1]; // Retain incomplete message chunk
    };
    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.close();
      }
    };
  }, []);

  function formatMessage(message) {
    const lines = message.split("\n");
    let formattedMessage = "";
    let inList = false;

    lines.forEach((line) => {
      line = line.trim();

      if (!line) return; // Skip empty lines

      // Detect numbered headings (e.g., "1. Engine") and format them
      if (line.match(/^\d+\.\s/)) {
        if (inList) {
          formattedMessage += "</ul>"; // Close any open list
          inList = false;
        }
        const headingText = line.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"); // Handle bold text in headings

        formattedMessage += `<h1 class="my-2">${headingText}</h1>`; // Add heading
      } else if (line.match(/^-|^\d+\.\d+\.\s/)) {
        // Detect list items (bulleted or sub-numbered lists)
        if (!inList) {
          formattedMessage += `<ul class="my-0.5 pl-5">`; // Start list
          inList = true;
        }
        const listItemText = line.replace(/^-|^\d+\.\d+\.\s*/, "").trim(); // Remove list indicator
        const boldFormatted = listItemText.replace(
          /\*\*(.*?)\*\*/g,
          "<b>$1</b>"
        ); // Handle bold text
        formattedMessage += `<li>${boldFormatted}</li>`;
      } else {
        // Handle regular text (paragraphs)
        if (inList) {
          formattedMessage += "</ul>"; // Close any open list
          inList = false;
        }
        const boldFormatted = line.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>"); // Handle bold text
        formattedMessage += `<p class="my-2">${boldFormatted}</p>`;
      }
    });

    if (inList) {
      formattedMessage += "</ul>"; // Close any open list
    }

    return formattedMessage;
  }

  useEffect(() => {
    const vrmValue = params.get("vrm");
    const modeValue = params.get("mode");
    const hexValue = params.get("hex");
    const button = params.get("button");

    if (isLoadingSocket) {
      if (vrmValue) {
        if (modeValue) {
          socket.send(modeValue);
        }
      
        setVrm(vrmValue);
        socket.send(vrmValue); // Send VRM
      }
      // if(modeValue){
      //   setMode(modeValue);
      //   socket.send(modeValue)
      // }
      if (hexValue) {
        setHex(`#${hexValue}`);
      }
      if (button) {
        const option = quickOptions.find((option) => option.id === button);
        // console.log(option.text);
        handleOnClickFeature(option.text);
      }
    }
  }, [isLoadingSocket]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      // messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [input]);

  useEffect(() => {
    if (messagesEndRef.current && screen) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (socket) {
        socket.close();
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [socket]);

  // const appendMessage = (content, sender) => {
  //   setMessages((prevMessages) => [
  //     ...prevMessages,
  //     { id: uuidv4(), text: content, sender },
  //   ]);
  //   const scrollbar = document.getElementsByClassName("custom-scrollbar");

  //   // Check if the scrollbar container exists
  //   if (scrollbar.length > 0) {
  //     const container = scrollbar[0]; // Get the first container
  //     container.scrollTop = container.scrollHeight; // Scroll to the bottom of the container
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!input.trim() || !socket) return;

    combineMessages(); // Consolidate chunk messages
    setIsLoading(true);
    appendMessage(input, "user"); // Add user input
    setInput("");

    if (socket.readyState === WebSocket.OPEN) {
      responseRef.current = ""; // Clear for new response
      socket.send(input);
    }
  };

  const appendMessage = (content, sender) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { id: uuidv4(), text: content, sender },
    ]);
  };

  function adjustHexColor(hex, percent) {
    const num = parseInt(hex.slice(1), 16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      G = ((num >> 8) & 0x00ff) + amt,
      B = (num & 0x0000ff) + amt;

    return `#${(
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  }

  // console.log('Light to Dark Variants:', lightToDarkVariants);
  const combineMessages = () => {
    if (messagesBot.length > 0) {
      const combinedText = messagesBot.map((msg) => msg.text).join(" ");
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: uuidv4(), text: combinedText, sender: "bot" },
      ]);
      setMessagesBot([]); // Clear chunk messages
    }
  };
  const handleOnClickFeature = (value) => {
    if (!socket) return;

    combineMessages(); // Consolidate chunk messages
    setIsLoading(true);
    appendMessage(value, "user");
    socket.send(value);
  };

  const handleKeyDown = (e) => {
    if (!isLoading) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        setIsLoading(true);
        handleSubmit(e);
      }
    }
  };

  const extractArray = (response) => {
    const arrayRegex = /\[([^\]]+)\]/;
    const arrayMatch = response.match(arrayRegex);

    let extractedArray = [];
    if (arrayMatch && arrayMatch[1]) {
      extractedArray = arrayMatch[1].split(",").map((item) => item.trim());
      setFeaturesArray(extractedArray);
    }
    // console.log(extractedArray);
    return [extractedArray.length, response.replace(arrayRegex, "").trim()];
  };

  // console.log(featuresArray);

  const parseBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  const chatVariants = {
    closed: { opacity: 0, scale: 0.8, y: 100 },
    open: { opacity: 1, scale: 1, y: 0 },
  };

  const messageVariants = {
    initial: { opacity: 0, y: 20, scale: 0.9 },
    animate: { opacity: 1, y: 0, scale: 1 },
    exit: { opacity: 0, scale: 0.9, transition: { duration: 0.2 } },
  };

  const sendMessageVariants = {
    initial: { scale: 1 },
    animate: {
      scale: [1, 1.2, 0.8, 1.1, 1],
      rotate: [0, 10, -10, 5, 0],
      transition: { duration: 0.5 },
    },
  };
  const welcomeVariants = {
    initial: { opacity: 0, scale: 0.8 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0.5, duration: 0.5 },
    },
    exit: { opacity: 0, scale: 0.8, transition: { duration: 0.3 } },
  };

  const sendInteractivityReportToParent = () => {
    const message = { type: "chatbotupdate", value: 1 };
    window.parent.postMessage(message, "*"); // Replace '*' with the parent's specific origin for security }
  };
  console.log(messages);
  return (
    <div
      className="max-w-6xl mx-auto overflow-y-hidden h-full"
      onClick={sendInteractivityReportToParent}
    >
      <AnimatePresence tabIndex={-1}>
        {isLoadingSocket && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={chatVariants}
            transition={{ type: "spring", stiffness: 400, damping: 30 }}
            className="h-[500px] overflow-hidden min-[490px]:h-[500px] flex flex-col relative"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <div className="overflow-y-auto overflow-x-hidden text-sm px-4  h-full custom-scrollbar">
              {
                <div className=" my-12 hidden min-[500px]:block mx-2 text-center">
                  <img className="mx-auto" src={ChatIcon} alt="" />
                  <h3 className="text-2xl font-medium mt-[-16px]">
                    Chat with our AI Chatbot
                  </h3>
                  <p className="mb-3 text-base">
                    Ask any question and our AI will answer!
                  </p>

                  <div className="w-full flex gap-2 ">
                    {quickOptionsWithIcon.map(
                      (option) =>
                        option.text !== undefined && (
                          <button
                            key={option.id}
                            onClick={() => handleOnClickFeature(option.text)}
                            disabled={isLoading}
                            className="text-base p-4 rounded-lg bg-[#F6F6F6] transition-colors disabled:opacity-50 w-6/12 text-start border-gray-[#EDEDED] border flex items-center"
                          >
                            {option.icon}
                            {option.text}
                          </button>
                        )
                    )}
                  </div>
                </div>
              }
              <AnimatePresence initial={false}>
                {/* {messages.map((message, index) => (
                  <motion.div
                    key={message.id ? `user-${message.id}` : `user-${index}`}
                    variants={messageVariants}
                    initial="initial"
                    animate="animate"
                    className={`flex justify-end`}
                  >
                    <div
                      className={`flex items-end space-x-2 sm:max-w-[75%] w-full text-sm flex-row-reverse space-x-reverse`}
                    >
                      <div
                        className={`px-4 rounded-md text-sm text-white mb-4 py-4`}
                        style={{ backgroundColor: hex }}
                      >
                        <span
                          className="text-xs md:text-sm break-words whitespace-pre-wrap"
                          dangerouslySetInnerHTML={{ __html: message.text }}
                        />
                      </div>
                    </div>
                  </motion.div>
                ))}

                {messagesBot.length > 0 && (
                  <motion.div
                    key={"bot"}
                    variants={messageVariants}
                    initial="initial"
                    animate="animate"
                    className="pt-2 pb-4 bg-gray-200 sm:max-w-[75%] mb-4 w-full rounded-md"
                  >
                    {messagesBot.map((message, index) => (
                      <div
                        key={message.id ? `bot-${message.id}` : `bot-${index}`}
                        className={`flex justify-start space-x-2 text-sm`}
                      >
                        <div
                          className={`px-4 text-sm text-gray-800 flex items-end`}
                        >
                          <span
                            className="text-xs md:text-sm break-words whitespace-pre-wrap"
                            dangerouslySetInnerHTML={{ __html: message.text }}
                          />
                        </div>
                      </div>
                    ))}
                  </motion.div>
                )} */}
                <div
                  key={"chat-messages"}
                  className="flex flex-col gap-y-2 overflow-y-auto w-full"
                >
                  {messages.map((message) => (
                    <div
                      key={message.id}
                      className={`flex ${
                        message.sender === "user"
                          ? "justify-end"
                          : "justify-start"
                      }`}
                    >
                      <div
                        className={`px-4 py-2 rounded-md mb-2 max-w-[75%] ${
                          message.sender === "user"
                            ? "bg-blue-500 text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: message.text }}
                        />
                      </div>
                    </div>
                  ))}
                  {messagesBot.length > 0 && (
                    <div
                      key={"bot"}
                      className="px-4 py-2 bg-gray-200 rounded-md max-w-[75%]"
                    >
                      {messagesBot.map((message) => (
                        <div key={message.id} className="flex justify-start">
                          <div className="">
                            <span
                              dangerouslySetInnerHTML={{ __html: message.text }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>

                <div
                  ref={messagesEndRef}
                  className="block min-[500px]:hidden"
                />
              </AnimatePresence>

              {isLoading && (
                <div className="flex justify-center">
                  <motion.div
                    className="bg-gray-200 p-3 rounded-full flex items-center space-x-2"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      repeat: Infinity,
                      duration: 1,
                      repeatType: "reverse",
                    }}
                  >
                    <motion.div
                      animate={{
                        x: [0, 30, 0],
                        transition: {
                          duration: 1.5,
                          repeat: Infinity,
                          ease: "linear",
                        },
                      }}
                    >
                      <Car size={24} style={{ color: hex }} />
                    </motion.div>
                  </motion.div>
                </div>
              )}
            </div>
            {
              <div className=" mb-0 sm:mb-12 min-[500px]:block mx-4">
                <p className="text-[#757676] mb-2 text-sm">
                  Try asking the AI Chatbot
                </p>

                <div className="w-full flex flex-wrap gap-2">
                  {quickOptions.map(
                    (option) =>
                      option.text !== undefined && (
                        <button
                          key={option.id}
                          onClick={() => handleOnClickFeature(option.text)}
                          disabled={isLoading}
                          className="text-sm lg:text-base py-1.5 px-3 rounded-lg bg-[#F6F6F6] transition-colors disabled:opacity-50 w-full sm:w-[49%] text-start border-gray-[#EDEDED] border mb-2 sm:mb-0"
                        >
                          {option.text}
                        </button>
                      )
                  )}
                </div>
              </div>
            }
            <div className="min-[500px]:h-fit  mt-2 ">
              <div className=" min-[500px]:relative w-full">
                <form
                  onSubmit={handleSubmit}
                  className="flex w-full items-center gap-3 lg:justify-between"
                >
                  <div className="relative w-full ">
                    <textarea
                      ref={textareaRef}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      disabled={isLoading}
                      placeholder="Ask about a car..."
                      className="w-full mb-3 min-[500px]:mb-0  hidden min-[500px]:block px-4 py-3 placeholder:text-sm bg-[#FAFAFA] text-gray-800 border min-h-12 max-h-32 border-gray-[#EDEDED] rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-300 resize-none overflow-hidden disabled:cursor-not-allowed placeholder:flex placeholder:justify-center rounded-[5rem]"
                      rows={1}
                    />
                    <textarea
                      ref={textareaRef}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={handleKeyDown}
                      disabled={isLoading}
                      placeholder="Ask about a car..."
                      className=" w-full px-4 py-3 min-[500px]:hidden placeholder:text-sm bg-[#FAFAFA] text-gray-800 border min-h-12 max-h-32 border-gray-[#EDEDED] rounded-xl focus:outline-none focus:ring-1 focus:ring-gray-300  resize-none overflow-hidden disabled:cursor-not-allowed placeholder:flex placeholder:justify-center rounded-[5rem]"
                      rows={1}
                    />

                    {/* <Search
                      className="absolute left-3 top-4 text-gray-400"
                      size={18}
                    /> */}
                    <motion.button
                      type="submit"
                      className="hidden min-[500px]:flex text-white rounded-lg transition-colors disabled:opacity-50 right-1 absolute top-[4px]"
                      // style={{ backgroundColor: hex }}
                      disabled={isLoading}
                      variants={sendMessageVariants}
                      whileTap="animate"
                    >
                      {/* <Send size={20} /> */}
                      <img src={SendButton} alt="Send" width={42} />
                    </motion.button>
                    <motion.button
                      type="submit"
                      className="text-brand-200 flex min-[500px]:hidden  transition-colors disabled:opacity-50 right-1 absolute top-[4px]"
                      // style={{ backgroundColor: hex }}
                      disabled={isLoading}
                      variants={sendMessageVariants}
                      whileTap="animate"
                    >
                      {/* <SendHorizontal size={20} /> */}
                      <img src={SendButton} alt="Send" width={42} />
                    </motion.button>
                  </div>
                </form>
              </div>
            </div>
            {/* {!showWelcome && (
                <div className="flex md:grid grid-cols-3 md:overflow-x-hidden overflow-x-scroll gap-4 mt-1 mx-5 pb-2 md:pb-0 custom-scrollbar">
                  {featuresArray.map((option) => (
                    <button
                      key={option}
                      onClick={() => handleOnClickFeature(option)}
                      disabled={isLoading}
                      className="text-sm py-1.5 px-3 rounded-lg shrink-0  transition-colors disabled:opacity-50 w-56 sm:w-full"
                      style={{
                        backgroundColor: lightToDarkVariants[0],
                        color: hex,
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )} */}
            {/* <div className="mx-4 text-slate-700 py-1 px-2 rounded-md mt-2"
              style={{ backgroundColor: lightToDarkVariants[0] }} >
              Theo, the AI agent, is in Beta mode. The information provided is for general purposes only and may be incomplete, inaccurate, or out of date. While we strive for accuracy, Theo, the AI agent may make mistakes. We do not guarantee the reliability of the content or any related products and services. No warranties, expressed or implied. Please note, we use data from interactions to train and improve our models.
              </div>   */}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
