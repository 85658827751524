import React from "react";
import ChatBot from "./components/ChatBot";
import { Overview } from "./components/Overview";

function App() {
  return (
    <div className="max-w-7xl mx-auto h-full">
      {/* <Overview /> */}
      {/* <hr /> */}
      <ChatBot />
    </div>
  );
}

export default App;
